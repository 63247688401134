<h1 class="p-3 p-xxl-5 mb-0 pb-xxl-2">\\Outdoor</h1>
<div class="d-flex h-100 pb-5">
  <div class="d-flex flex-column overflow-hidden w-100 h-100 gap-3 px-5">
    <div class="d-flex justify-content-end gap-3 modify-background">
      <button class="filter btn button-border-left font-Lato-regular text-xxs px-4 me-auto"
        (click)="toggleMapAbiablility()">
        {{isMapEnabled ? 'Deshabilitar' : 'Habilitar'}} mapa
      </button>
      <button class="filter btn button-border-left font-Lato-regular text-xxs px-4" (click)="changeSelector(0)"
        [ngClass]="{'selected' : outdoorSelector == 0}">Mapa</button>
      <button class="filter btn button-border-left font-Lato-regular text-xxs px-4" (click)="changeSelector(1)"
        [ngClass]="{'selected' : outdoorSelector == 1}">
        Lista de puntos</button>
      <button class="filter btn button-border-left font-Lato-regular text-xxs px-4"
        (click)="changeSelector(2); actionType = 0" [ngClass]="{'selected' : outdoorSelector == 2}">Crear
        punto</button>
      <input class="search-bar w-20" type="text" name="search" id="search" placeholder="Búsqueda..."
        [(ngModel)]="searchInput">
    </div>

    <div class="d-flex h-100 gap-3">
      <div class="map" id="map" [ngStyle]="{
        'opacity': outdoorSelector != 1 ? '1' : '0',
        'display': outdoorSelector != 1 ? 'block': 'none',
      }"></div>
      <!--Crear punto-->
      <div *ngIf="outdoorSelector == 2" class="create-marker custom-inputs d-flex flex-column gap-3 w-50 pt-3 px-5 pb-5"
        [formGroup]="experienceForm">
        <div class="w-100 text-center">
          <h4 *ngIf="actionType == 0">CREAR PUNTO</h4>
          <h4 *ngIf="actionType == 1">INFO PUNTO</h4>
          <h4 *ngIf="actionType == 2">EDITAR PUNTO</h4>
          <img *ngIf="actionType == 1" (click)="actionType = 2" class="edit-svg" src="assets/edit.svg" alt="edit"
            (click)="actionType = 2">
          <img *ngIf="actionType == 1 && !experienceClicked.getIsPathWay()" class="delete-icon"
            src="assets/images/btn_close.svg" alt="delete" (click)="deleteMark(selectedMark.id)">
          <img *ngIf="actionType == 2" (click)="actionType = 1" src="assets/images/btn_close.svg" alt="close"
            (click)="actionType = 0">
        </div>

        <div class="input-container gap-3">
          <div class="input w-25">
            <p>Número</p>
            <input type="number" formControlName="experienceNumber" appRemoveLeadingZero
              (keyup)="updateNumber(this.experienceForm.value.experienceNumber)">
          </div>
          <div class="input">
            <p>Nombre</p>
            <input type="text" formControlName="experienceName">
          </div>
          <div class="input w-75 pe-none">
            <p class="text-center ps-0">Senda estelar</p>
            <div class="w-100 d-flex justify-content-center">
              <label class="custom-checkbox">
                <input type="checkbox" formControlName="experienceIsPathWay">
                <span class="checkbox"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="input-container gap-5">
          <div class="input">
            <p>Latitud</p>
            <input type="text" formControlName="experienceLat" (keyup)="updateLat()">
          </div>
          <div class="input">
            <p>Longitud</p>
            <input type="text" formControlName="experienceLon" (keyup)="updateLon()">
          </div>
        </div>
        <div class="input-container">
          <div class="input">
            <p>URL</p>
            <input type="text" formControlName="experienceUrl">
          </div>
        </div>
        <div class="input-container d-flex gap-3">
          <div class="input position-relative">
            <p>Color primario</p>
            <input type="color" class="hidden-input" id="TemplateColor1" formControlName="experienceColor"
              (change)="colorPrimarySelected($event)">
            <input type="text" value="{{ this.experienceColor }}" (click)="openColor('TemplateColor1')"
              [ngStyle]="{'background-color': this.experienceColor}">
          </div>
          <div class="input position-relative">
            <p>Color secundario (*)</p>
            <input type="color" class="hidden-input" id="TemplateColor2" formControlName="experienceSecondaryColor"
              (change)="colorSecondarySelected($event)">
            <input type="text" value="{{ this.experienceSecondaryColor }}" (click)="openColor('TemplateColor2')"
              [ngStyle]="{'background-color': this.experienceSecondaryColor}">
          </div>
          <div class="input position-relative">
            <p>Color texto</p>
            <input type="color" class="hidden-input" id="TemplateColor3" formControlName="experienceTextColor"
              (change)="colorTextSelected($event)">
            <input type="text" value="{{ this.experienceTextColor }}" (click)="openColor('TemplateColor3')"
              [ngStyle]="{'background-color': this.experienceTextColor}">
          </div>
        </div>
        <p class="disclaimer">
          (*) El color secundario se combina con el primario para crear el degradado de color en el fondo de los
          carteles de
          info.
        </p>
        <div class="input-container d-flex gap-3">
          <div class="input position-relative">
            <p>Descripción</p>
            <textarea placeholder="Notas sobre esta experiencia Outdoor" formControlName="experienceNotes"></textarea>
          </div>
        </div>
        <div *ngIf="actionType == 0 || actionType == 2" class="input-container justify-content-around mt-auto">
          <div class="button-triangle-wrapper w-auto">
            <button class="button" type="submit" id="confirmar"
              (click)="this.actionType = 0; this.outdoorSelector = 0">Cerrar</button>
          </div>
          <div class="button-triangle-wrapper w-auto">
            <button *ngIf="actionType == 0" class="button" type="submit" id="confirmar" (click)="submitMarker()"
              [disabled]="!experienceForm.valid">Crear</button>
            <button *ngIf="actionType == 2" class="button" type="submit" id="confirmar" (click)="submitMarker()"
              [disabled]="!experienceForm.valid">Confirmar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Lista de puntos -->
    <div *ngIf="outdoorSelector == 1">
      <div class="table-content">
        <div class="d-flex table_header">
          <div class="header col col-1 p-2">
            Orden
            <img (click)="toggleSortExperienceNumber()" src="assets/filter-icon.svg" alt="filter">
          </div>
          <div class="header col col-2 p-2">
            Nombre
            <img (click)="toggleSortByName()" src="assets/filter-icon.svg" alt="filter">
          </div>
          <div class="header col col-3 d-flex justify-content-center p-2">
            Lugar
            <img (click)="toggleSortByLocation()" src="assets/filter-icon.svg" alt="filter">
          </div>
        </div>
        <div class="table_body">
          <div *ngFor="let mapExperience of sortedMapExperience | mapExperienceFilter: searchInput"
            class="d-flex table_row">
            <span class="select col col-1 p-2 fw-bold">
              {{ mapExperience.getExperienceNumber() }}
              <img *ngIf="mapExperience.getIsPathWay()" src="assets/images/icono_sendaEstelar.svg" class="small-icon">
            </span>
            <span class="select col col-2 p-2">{{ mapExperience.getTitle() }}</span>
            <span class="col col-3 p-2">{{ mapExperience.getIsPathWay() == true ? 'SENDA ESTELAR' : 'OTRAS'
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Popup confirmacion -->
  <div class="popup-confirmation d-flex justify-content-center align-items-center" [ngStyle]="{
  'opacity': popupConfirmation ? '1': '0',
  'pointer-events': popupConfirmation ? 'all': 'none'
}">
    <div class="popup-content">
      <p class="py-2">{{ popupText }}</p>
      <div class="py-2">
        <div (click)="confirmPopup()" class="button-triangle-wrapper w-auto"><button class="button">Confirmar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-confirmation d-flex justify-content-center align-items-center" [ngStyle]="{
  'opacity': popupConfirmationDelete ? '1': '0',
  'pointer-events': popupConfirmationDelete ? 'all': 'none'
}">
    <div class="popup-content">
      <p class="py-2">¿Estás seguro que quieres eliminar el marcador?</p>
      <div class="py-2">
        <div (click)="this.popupConfirmationDelete = false" class="button-triangle-wrapper w-auto"><button
            class="button">Cancelar</button></div>
        <div (click)="confirmDelete()" class="button-triangle-wrapper w-auto"><button class="button">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
