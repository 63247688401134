<ngb-datepicker *ngIf="hasCalendarData" #dp [(ngModel)]="model" (navigate)="date = $event.next"
  [outsideDays]="outsideDays" [navigation]="navigation" class="custom-date-picker h-100"
  [contentTemplate]="datePickerCustom" [dayTemplate]="customDayTemplate" (dateSelect)="getAvailableTimes(model)"
  [ngStyle]="{'border': isBorderless ? '0px solid black' : '2px solid #ccc'}" />

<ng-template #datePickerCustom let-dp>
  <div class="date-picker-template h-100 d-flex flex-column overflow-auto" [ngStyle]="{'background-color': bgColor}">
    <div class="title-content d-flex justify-content-between align-items-center mb-3 py-1 py-xxl-3"
      [ngStyle]="{'padding-left': paddingl, 'padding-right': paddingr}">
      <div class="border-custom d-flex align-items-center px-0">
        <button type="button" class="btn btn-transparent flex-0 me-1 custom-btn" (click)="navigate(dp, -1)">
          <span class="d-flex">
            <img class="image-size" src="assets/images/arrowL.svg" alt="Anterior" />
          </span>
        </button>
        <div class="separator"></div>
        <button type="button" class="btn btn-transparent flex-0 ms-1 custom-btn" (click)="navigate(dp, 1)">
          <span class="d-flex">
            <img class="image-size" src="assets/images/arrowR.svg" alt="Siguiente" />
          </span>
        </button>
      </div>
      <button type="button" class="btn btn-transparent border-custom flex-0 custom-btn label-hoy px-3 ms-3 me-auto"
        #todayButtonLeft *ngIf="showDay2" (click)="goToToday(dp)">
        Hoy
      </button>
      <div class="text-white calendar-month font-Lato-bold mx-auto text-center">
        {{ dp.i18n.getMonthShortName(dp.state.focusedDate.month) }} {{ dp.state.focusedDate.year }}
      </div>
      <button type="button" class="btn btn-transparent border-custom flex-0 custom-btn label-hoy px-3" *ngIf="showDay"
        #todayButtonRight (click)="goToToday(dp)">
        Hoy
      </button>
      <button type="button" class="btn btn-transparent border-custom flex-0 custom-btn label-hoy px-3" *ngIf="showClose"
        (click)="closeCalendar()">
        cerrar
      </button>

    </div>
    <div class="custom-month-grid overflow-hidden h-100">
      <ngb-datepicker-month *ngFor="let month of dp.state.months; trackBy: trackByMonth" class="rounded "
        [ngClass]="{'flexive': !isBorderless}" [month]="month"></ngb-datepicker-month>
    </div>
    <div class="legend mt-2 mt-xxl-4" *ngIf="showLegend">
      <div class="leyend-content d-flex flex-wrap justify-content-evenly align-items-center text-white ">
        <div *ngFor="let template of templates; index as i" class="legend-item na d-flex align-items-center py-2 ms-2">
          <span class="legend-indicator" [style]="'background-color: ' + template.templateColour"></span>
          <span class="label-na ms-2" [style]="'color: ' + template.templateColour">{{template.templateName}}</span>
        </div>
        <div class="legend-item na d-flex align-items-center ms-2">
          <span class="legend-indicator" style="background-color: #9e9e9e"></span>
          <span class="label-na ms-2" style="color: #9e9e9e">NA</span>
        </div>
      </div>
    </div>

    <div class="legend mt-2 mt-xxl-4" *ngIf="showFooter">
      <div class="d-flex justify-content-center gap-5 pb-2 align-items-center text-white">
        <button (click)="buttonClickEvent(1)" type="button"
          class="btn btn-transparent border-custom flex-0 custom-btn label-hoy px-3" [ngStyle]="{
            'background-color': typeEvent == 1 ? '#0600ff' : '#213948'
          }">
          Editar calendario
          <img src="assets/config.svg" alt="Usuarios" class="bottom-icon">
        </button>
        <button (click)="buttonClickEvent(2)" type="button"
          class="btn btn-transparent border-custom flex-0 custom-btn label-hoy px-3" [ngStyle]="{
            'background-color': typeEvent == 2 ? '#0600ff' : '#213948'
          }">
          Editar día
          <img src="assets/edit.svg" alt="Usuarios" class="bottom-icon">
        </button>
      </div>
    </div>

    <div class="legend mt-2 mt-xxl-4" *ngIf="showTemplate">
      <br>
      <p class="label-showDays">Selecciona todos los dias para aplicar la plantilla</p>
      <div class="row w-100 gap-5 pb-2 justify-content-center align-items-center">
        <div class="col-8">
          <select class="border-custom flex-0 custom-select label-hoy px-2 w-100" [(ngModel)]="templateId">
            <option disabled selected value="0">Seleccionar plantilla</option>
            <option *ngFor="let template of templatesWork" [value]="template.getId()">{{ template.getTemplateName() }}
            </option>
          </select>
        </div>
        <div class="col-2">
          <button class="btn btn-transparent border-custom flex-0 custom-btn2 label-hoy px-2 w-100"
            (click)="sendDaysWithTemplate()">Confirmar</button>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #customDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected">
  <span class="custom-day custom_day_calendar" [class.selected]="selected"
    [class.disabled]="!isAvailable(date) && !isLowAvailability(date)"
    [style]="date | dateStyle:templatesInfo: seeColors" (click)="setPink($event)">
    {{ date.day }}
  </span>
</ng-template>
