<h1 class="p_5 pb-0">\\USUARIOS</h1>
<div class="table px-5 m_tables">
  <div class="top-elements d-flex flex-row-reverse gap-4">
    <button class="filter btn button-border-left font-Lato-regular text-xxs pe-3" (click)="openPopup()">
      <img src="assets/hamburger-icon.svg" alt="closeButton"> Filtros
    </button>
    <!-- Pop-up -->
    <div class="popup" *ngIf="isPopupVisible" (click)="onBackdropClick($event)">
      <div class="popup-content">
        <span class="close" (click)="closePopup()">&times;</span>
        <p class="p-filtrado">FILTRADO</p>
        <div class="popup-content-button">
          <button class="popup-button" (click)="addFilter('SuperAdmin')">SuperAdmin</button>
          <button class="popup-button" (click)="addFilter('Admin')">Admin</button>
          <button class="popup-button" (click)="addFilter('Client')">User</button>
          <button class="popup-button" (click)="addFilter('Control')">Control</button>
          <button class="popup-button" (click)="addFilter('Bloqueado')">Bloqueado</button>
        </div>
        <button class="popup-button" (click)="addFilter('Suspendido')">Suspendido</button>
      </div>
    </div>
    <input class=".search-bar" type="text" name="search" id="search" placeholder="Búsqueda..."
      [(ngModel)]="searchFilter">
    <!-- Filtros -->
    <div class="filters" *ngFor="let filtro of this.filters">
      {{ filtro }}
      <img src="assets/images/btn_close.svg" alt="closeButon" (click)="removeFilter(filtro)">
    </div>
  </div>
  <div class="table-content mt-4">
    <div class="d-flex table_header">
      <div class="header col col-3 me-auto d-flex justify-content-left">
        <div class=" header_row ps-5">
          Usuarios
          <img (click)="toggleSortUsers()" src="assets/filter-icon.svg" alt="filter">
        </div>
      </div>
      <div class="header col col-2">
        Rol
        <img (click)="toggleSortRole()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-2">
        Acciones
      </div>
    </div>
    <div class="table_body">
      <div *ngFor="let user of this.sortedUsers | user: searchFilter : filters"
        class="d-flex align-items-center table_row user_role">
        <span class="col col-3 me-auto text-start ps-5">{{ user.id }}</span>
        <span class="select col col-2">
          <span *ngIf="editUserAction != user.id">{{ roles[user.role] }}</span>
          <select *ngIf="editUserAction == user.id" name="status" id="{{ user.id }}" value="{{ user.role }}"
            [(ngModel)]="user.role">
            <option value="client">Usuario</option>
            <option value="admin">Admin</option>
            <option value="superadmin">SuperAdmin</option>
            <option value="control">Control</option>
            <option value="blocked">Bloqueado</option>
          </select>
        </span>
        <div class="d-flex icon_container col col-2">
          <img (click)="editUser(user.id)" src="assets/edit.svg" alt="edit" class="icon" id="{{ user.id }}edit">
          <img *ngIf="this.editUserAction == user.id" (click)="cancelEditUser(user.id)" src="assets/btn_noX.png"
            alt="cancel" class="icon">
          <img *ngIf="this.editUserAction != user.id" (click)="deleteUser(user.id)" src="assets/delete-icon.svg"
            alt="delete" class="icon">
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Popup confirmacion -->
<div class="popup-confirmation d-flex justify-content-center align-items-center" [ngStyle]="{
  'opacity': popupConfirmation ? '1': '0',
  'pointer-events': popupConfirmation ? 'all': 'none'
}">
  <div class="popup-content">
    <p class="py-2">¿Seguro que quiere borrar el usuario?</p>
    <div class="py-2">
      <div class="button-triangle-wrapper w-auto"><button (click)="this.popupConfirmation = false"
          class="button">Cancelar</button></div>
      <div class="button-triangle-wrapper w-auto"><button (click)="confirmDeleteUser()"
          class="button">Confirmar</button>
      </div>
    </div>
  </div>
</div>
