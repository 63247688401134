import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { UserTypeEnum } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services';
@Injectable()
export class IsAdminLoggedGuard {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(): Observable<boolean> {
    return this.authService.user$.pipe(
      filter((user) => user !== null),
      map((user) => {
        if (
          user?.role === UserTypeEnum.Admin ||
          user?.role === UserTypeEnum.SuperAdmin
        ) {
          /* console.log("User Admin", user)
          void this.router.navigate(['/admin']); */
          return true;
        }

        if (
          user?.role === UserTypeEnum.Blocked ||
          user?.role === UserTypeEnum.Suspended
        ) {
          void this.router.navigate(['/blocked']);
          return false;
        }

        void this.router.navigate(['/']);
        return false;
      }),
    );
  }
}
