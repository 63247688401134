import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import maplibregl from 'maplibre-gl';
import { ToastrService } from 'ngx-toastr';
import { MapExperience } from 'src/app/shared/models/MapExperience';
import { MapService } from 'src/app/shared/services/map.service';

@Component({
  selector: 'app-admin-map',
  templateUrl: './adminMap.component.html',
  styleUrls: ['./adminMap.component.scss'],
})
export class AdminMapComponent implements OnInit, OnInit {
  @Output() currentSectionChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() currentSection: number = 0;
  map: maplibregl.Map | undefined;
  currentMarker: maplibregl.Marker | null = null;
  markerCoordinates: maplibregl.LngLat | null = null;
  searchInput: string = '';
  searchResults: any[] = [];
  showSearchBar: boolean = false;
  mapExperiences: MapExperience[] = [];
  sortOrder: 'asc' | 'desc' = 'asc';
  sortedMapExperience: MapExperience[] = [];
  markerBGColor: string = '#666666';
  markerBorderColor: string = '#000000';
  markerTextColor: string = '#FFFFFF';
  markerNumber: number = 0;
  outdoorSelector: number = 0;

  actionType = 0;
  previousActionType: number = -1;

  selectedMark: any = 0;
  createMarkForm: any;
  idSelectedMark: number | undefined;
  popupConfirmation: boolean = false;
  popupConfirmationDelete: boolean = false;
  popupText: string = "";

  mapMarkers: maplibregl.Marker[] = []
  //Form
  experienceNotes: string = '';
  experienceName: string = '';
  experienceIsPathWay: boolean = false;
  experienceUrl: string = '';
  experienceLat: number = 0;
  experienceLon: number = 0;
  experienceColor: string = '#213948';
  experienceSecondaryColor: string = '#213948';
  experienceTextColor: string = '#213948';
  experienceForm: any;
  experieceGroup: any;
  isButtonDisabled = false;
  // Lista de marcadores
  markers: { id: number; coordinates: maplibregl.LngLat; info: string }[] = [];
  currentMarkerInfo: string | null = null;

  experienceClicked: any;
  isMapEnabled: boolean = false;

  constructor(
    private mapService: MapService,
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['currentSection'] &&
      changes['currentSection'].currentValue == 6
    ) {
      this.getMapEnabled();
      this.initMap();
    }
  }

  ngDoCheck(): void {
    if (this.actionType !== this.previousActionType) {
      if (this.actionType === 1) {
        this.experienceForm.disable();
      } else {
        this.experienceForm.enable();
      }

      // Cuando se pasa de info/editar a crear marcador se limpia el html del marcador anterior
      if ((this.previousActionType == 1 || this.previousActionType == 2) && this.actionType == 0) {
        this.clearMarkerHtml(this.selectedMark.id)
      }

      // Se ejecuta cuando se pasa de editar a info (para resetear el html del marcador)
      if(this.previousActionType == 2 && this.actionType == 1) this.clearMarkerHtml(this.selectedMark.id)

      if (this.actionType == 0) {
        this.clearValues()
      }

      this.previousActionType = this.actionType;
    }
  }

  initForm() {
    this.experieceGroup = new FormGroup({ firstName: new FormControl() });
    this.experienceForm = this.formBuilder.group({
      experienceName: ['', Validators.required],
      experienceNumber: [0, [Validators.required, this.greaterThanZeroValidator]],
      experienceNotes: '',
      experienceIsPathWay: [false, Validators.required],
      experienceUrl: [''],
      experienceLat: [0, Validators.required],
      experienceLon: [0, Validators.required],
      experienceColor: [this.experienceColor, Validators.required],
      experienceSecondaryColor: [
        this.experienceSecondaryColor,
        Validators.required,
      ],
      experienceTextColor: [this.experienceTextColor, Validators.required],
    });
  }

  greaterThanZeroValidator(control: AbstractControl): ValidationErrors | null {
    return control.value > 0 ? null : { notGreaterThanZero: true };
  }

  getMapEnabled() {
    this.mapService.getMapEnabled().subscribe((response: any) => this.isMapEnabled = response.data,
      (error: any) => this.showErrorToast(error));
  }

  initValues(mark: number) {
    const experience = this.mapExperiences.find(exp => exp.getId() === mark)
    this.experienceClicked = experience;
    this.experienceForm.get('experienceNumber')?.setValue(experience?.getExperienceNumber());
    this.experienceForm.get('experienceName')?.setValue(experience?.getTitle());
    this.experienceForm.get('experienceIsPathWay')?.setValue(experience?.getIsPathWay());
    this.experienceForm.get('experienceLat')?.setValue(experience?.getLat());
    this.experienceForm.get('experienceLon')?.setValue(experience?.getLon());
    this.experienceForm.get('experienceUrl')?.setValue(experience?.getUrl());
    this.experienceForm.get('experienceNotes')?.setValue(experience?.getDescription());

    this.experienceForm.get('experienceColor')?.setValue(experience?.getColor());
    this.experienceForm.get('experienceSecondaryColor')?.setValue(experience?.getSecondaryColor());
    this.experienceForm.get('experienceTextColor')?.setValue(experience?.getTextColor());

    this.experienceColor = experience!.getColor()
    this.experienceSecondaryColor = experience!.getSecondaryColor()
    this.experienceTextColor = experience!.getTextColor()

    this.markerBGColor = experience!.getColor()
    this.markerBorderColor = experience!.getSecondaryColor()
    this.markerTextColor = experience!.getTextColor()
    this.markerNumber = experience!.getExperienceNumber()
  }

  clearValues() {
    this.experienceForm.get('experienceNumber')?.setValue(0);
    this.experienceForm.get('experienceName')?.setValue();
    this.experienceForm.get('experienceIsPathWay')?.setValue(0);
    this.experienceForm.get('experienceUrl')?.setValue();
    this.experienceForm.get('experienceNotes')?.setValue();

    this.experienceForm.get('experienceColor')?.setValue('#666666');
    this.experienceForm.get('experienceSecondaryColor')?.setValue('#000000');
    this.experienceForm.get('experienceTextColor')?.setValue('#FFFFFF');

    this.experienceColor = "#213948";
    this.experienceSecondaryColor = "#213948";
    this.experienceTextColor = "#213948";
  }


  getMapExperiences() {
    this.mapService.getMapExperiences().subscribe(
      (mapExperiences: any) => {
        this.mapExperiences = mapExperiences.data.map(
          (mapExperience: any) =>
            new MapExperience(
              mapExperience.id,
              mapExperience.lat,
              mapExperience.lon,
              mapExperience.color,
              mapExperience.secondaryColor || '#000000',
              mapExperience.textColor || '#FFFFFF',
              mapExperience.title,
              mapExperience.description,
              mapExperience.url,
              mapExperience.isPathWay,
              mapExperience.experienceNumber
            ),
        );

        this.removeAllMarkers()

        this.mapExperiences.forEach((mapExperience) => {
          let lngLat: maplibregl.LngLat = new maplibregl.LngLat(
            mapExperience.getLon(),
            mapExperience.getLat(),
          );
          this.addMarker(lngLat, [mapExperience.getColor(), mapExperience.getSecondaryColor(), mapExperience.getTextColor(), mapExperience.getId()]);
        });

        this.sortedMapExperience = [...this.mapExperiences].sort((a, b) => a.getExperienceNumber() - b.getExperienceNumber());
        this.currentMarker = null;
      },
      (error) => {
        console.error(error);
        this.showErrorToast(error)
      }
    );
  }

  removeAllMarkers() {
    this.mapMarkers.forEach(marker => {
      marker.remove();
    });

    this.mapMarkers = []
  }

  initMap() {
    this.mapMarkers = []
    this.map = new maplibregl.Map({
      container: 'map',
      style:
        'https://api.maptiler.com/maps/positron/style.json?key=dZvoUo5J6JSqP1ay3qiU',
      center: [-3.703184, 40.417045],
      zoom: 12,
    });

    this.map.on('load', () => {
      const customBuildingGeoJSON = this.getBuildings()
      this.map?.addSource('custom-building', {
        type: 'geojson',
        data: customBuildingGeoJSON,
      });
      this.map?.addLayer({
        id: 'custom-building-layer',
        type: 'fill',
        source: 'custom-building',
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.8,
        },
      });
    });

    let mapLibreUi = document.getElementsByClassName(
      'maplibregl-control-container',
    )[0];
    mapLibreUi?.setAttribute('style', 'display: none');

    this.getMapExperiences();

    this.map.on('click', (e: any) => {
      let isOverLaping: boolean = false;
      if (this.markerCoordinates || this.mapExperiences.length > 0) {
        const distance = this.markerCoordinates!.distanceTo(e.lngLat);
        if (this.areOverlaping(distance)) isOverLaping = true;
        this.mapExperiences.forEach((mapExperience) => {
          let lngLat: maplibregl.LngLat = new maplibregl.LngLat(
            mapExperience.getLon(),
            mapExperience.getLat(),
          );
          const distance = lngLat.distanceTo(e.lngLat);
          if (this.areOverlaping(distance)) isOverLaping = true;
        });
      }

      if (isOverLaping) return;

      this.deletePreviousMarker();
      this.addMarker(e.lngLat, null);
    });

    this.map.on("load", () => this.toggleSortExperienceNumber())
  }

  addMarker(coordinates: maplibregl.LngLat, data: any | null) {
    if (data) {
      this.markerBGColor = data[0];
      this.markerBorderColor = data[1];
      this.markerTextColor = data[2];
      this.markerNumber = this.getMapExperienceById(data[3]);
    } else {
      this.markerBGColor = '#666666';
      this.markerBorderColor = '#000000';
      this.markerTextColor = '#FFFFFF';
      this.markerNumber = 0;
      this.actionType = 0
    }

    this.currentMarker = new maplibregl.Marker({
      element: this.prepareMarkerHtml(),
    })
      .setLngLat(coordinates)
      .addTo(this.map!);
    this.mapMarkers.push(this.currentMarker)
    if (data) {
      let elem = this.currentMarker.getElement()

      const cleanDbid = data[3].toString().replace(/^0+/, '');
      elem.setAttribute("attr-dbid", data[3].toString())
      this.currentMarker.getElement().addEventListener('click', (e: any) => {
        let previousMarkerId = this.selectedMark.id;
        this.selectedMark = this.markers.find((marker: any) => marker.id == e.target.getAttribute("attr-dbid"))
        if (this.selectedMark?.id > 0) {
          this.clearMarkerHtml(previousMarkerId)
          this.currentMarker?.remove();
          this.currentMarker = null;
          this.changeSelector(2);
          this.actionType = 1
          this.initValues(this.selectedMark.id);
        } else {
          this.changeSelector(2)
          this.actionType = 0
        }
      });

      const markerId = data[3];
      const markerInfo = `Marcador ${markerId}: ${coordinates.lat.toFixed(4)}, ${coordinates.lng.toFixed(4)}`;
      this.markers.push({ id: markerId, coordinates, info: markerInfo });
      this.currentMarkerInfo = markerInfo;
    } else {
      this.experienceForm.get('experienceLat')?.setValue(coordinates.lat);
      this.experienceForm.get('experienceLon')?.setValue(coordinates.lng);
      this.changeSelector(2)
      this.clearValues()
    }

    this.markerCoordinates = coordinates;
    this.map!.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 17 });
  }

  clearMarkerHtml(id: number) {
    if (id == undefined) return;
    this.clearPosition(id)
    let markerUpdate = this.mapExperiences.find((marker: any) => marker.id == id)
    this.mapMarkers.forEach((mapMarker: maplibregl.Marker) => {
      let elem = mapMarker._element
      let elemId = elem.getAttribute('attr-dbid')
      if (elemId == markerUpdate?.getId().toString()) {
        elem.innerHTML = this.getMarkerHtmlWithParameters(markerUpdate!.getColor(), markerUpdate!.getSecondaryColor(), markerUpdate!.getTextColor(), this.getMapExperienceById(id))
      }
    })
  }

  clearPosition(id: number) {
    this.mapMarkers.forEach((mapMarker: maplibregl.Marker) => {
      let elem = mapMarker._element
      let markerAttributes = this.mapExperiences.find((marker: any) => marker.id == id)
      let idMarker = elem.getAttribute('attr-dbid')
      if (idMarker == id.toString()) {
        const newLngLat = new maplibregl.LngLat(markerAttributes!.getLon(), markerAttributes!.getLat());
        mapMarker.setLngLat(newLngLat);
      }
    })
  }

  updateNumber(number: number) {
    this.markerNumber = number;
    if (this.currentMarker) {
      this.currentMarker.getElement().innerHTML = this.getMarkerHtml();
    } else {
      this.mapMarkers.forEach((mapMarker: maplibregl.Marker) => {
        let elem = mapMarker._element
        let markerAttributes = this.mapExperiences.find((marker: any) => marker.id == this.selectedMark.id)
        let idMarker = elem.getAttribute('attr-dbid')
        if (idMarker == this.selectedMark.id) elem.innerHTML = this.getMarkerHtml()
      })
    }
  }

  deletePreviousMarker() {
    if (this.currentMarker) {
      this.currentMarker.remove();
    }
  }

  flyToMarker(coordinates: number[]) {
    let lngLat: maplibregl.LngLat = new maplibregl.LngLat(
      coordinates[0],
      coordinates[1],
    );
    this.deletePreviousMarker();
    this.addMarker(lngLat, null);
  }

  areOverlaping(distance: Number) {
    let zoomlevel = this.map!.getZoom();
    let threshold = this.calculateThresholdDistance(zoomlevel);
    return distance < threshold;
  }

  calculateThresholdDistance(zoomLevel: number): Number {
    if (zoomLevel < 7) return 250;
    else if (zoomLevel >= 7 && zoomLevel < 7.5) return 400;
    else if (zoomLevel >= 7.5 && zoomLevel < 8) return 350;
    else if (zoomLevel >= 8 && zoomLevel < 9) return 300;
    else if (zoomLevel >= 9 && zoomLevel < 10) return 200;
    else if (zoomLevel >= 10 && zoomLevel < 12) return 150;
    else if (zoomLevel >= 12 && zoomLevel < 14) return 100;
    else if (zoomLevel >= 14 && zoomLevel < 15) return 50;
    else if (zoomLevel >= 15 && zoomLevel < 16) return 30;
    else if (zoomLevel >= 16 && zoomLevel < 17) return 20;
    else if (zoomLevel >= 17 && zoomLevel < 18) return 16;
    else if (zoomLevel >= 18 && zoomLevel < 19) return 10;
    else return 1;
  }

  onMarkerClick(marker: {
    id: number;
    coordinates: maplibregl.LngLat;
    info: string;
  }) {
    if (this.map) {
      this.map.flyTo({
        center: [marker.coordinates.lng, marker.coordinates.lat],
        zoom: 14,
      });
      this.flyToMarker([marker.coordinates.lng, marker.coordinates.lat]);
    }
  }

  updateLat() {
    if (this.actionType == 2) {
      this.mapMarkers.forEach((mapMarker: maplibregl.Marker) => {
        let elem = mapMarker._element
        let id = elem.getAttribute('attr-dbid')
        if (id == this.selectedMark.id) {
          const newLngLat = new maplibregl.LngLat(mapMarker.getLngLat().lng, this.experienceForm.value.experienceLat);
          mapMarker.setLngLat(newLngLat);
        }
      })
    } else if (this.actionType == 0) {
      let mapMarker = this.mapMarkers[this.mapMarkers.length - 1]
      const newLngLat = new maplibregl.LngLat(mapMarker.getLngLat().lng, this.experienceForm.value.experienceLat);
      mapMarker.setLngLat(newLngLat);
    }
  }

  updateLon() {
    if (this.actionType == 2) {
      this.mapMarkers.forEach((mapMarker: maplibregl.Marker) => {
        let elem = mapMarker._element
        let id = elem.getAttribute('attr-dbid')
        if (id == this.selectedMark.id) {
          const newLngLat = new maplibregl.LngLat(this.experienceForm.value.experienceLon, mapMarker.getLngLat().lat);
          mapMarker.setLngLat(newLngLat);
        }
      })
    } else if (this.actionType == 0) {
      let mapMarker = this.mapMarkers[this.mapMarkers.length - 1]
      const newLngLat = new maplibregl.LngLat(this.experienceForm.value.experienceLon, mapMarker.getLngLat().lat);
      mapMarker.setLngLat(newLngLat);
    }
  }

  openColor(id: string) {
    let input = document.getElementById(id);
    input?.click();
  }

  prepareMarkerHtml() {
    const markerElement = document.createElement('div');
    markerElement.innerHTML = this.getMarkerHtml() as string;
    return markerElement;
  }

  colorPrimarySelected(event: any) {
    const selectedColor = event.target.value;
    this.experienceForm.get('experienceColor')?.setValue(selectedColor);
    this.experienceColor = selectedColor;
    this.markerBGColor = selectedColor;
    this.changeMarkerColor();
  }

  colorSecondarySelected(event: any) {
    const selectedColor = event.target.value;
    this.experienceForm.get('experienceSecondaryColor')?.setValue(selectedColor);
    this.experienceSecondaryColor = selectedColor;
    this.markerBorderColor = selectedColor;
    this.changeMarkerColor()
  }

  colorTextSelected(event: any) {
    const selectedColor = event.target.value;
    this.experienceForm.get('experienceTextColor')?.setValue(selectedColor);
    this.experienceTextColor = selectedColor;
    this.markerTextColor = selectedColor;
    this.changeMarkerColor()
  }

  changeMarkerColor() {
    if (this.currentMarker) {
      this.currentMarker.getElement().innerHTML = this.getMarkerHtml();
    } else {
      this.mapMarkers.forEach((mapMarker: maplibregl.Marker) => {
        let elem = mapMarker._element
        let id = elem.getAttribute('attr-dbid')
        if (id == this.selectedMark.id) elem.innerHTML = this.getMarkerHtml()
      })
    }
  }

  submitMarker() {
    if (this.actionType == 0) {
      let body = {
        lat: this.experienceForm.value.experienceLat,
        lon: this.experienceForm.value.experienceLon,
        color: this.experienceForm.value.experienceColor,
        secondaryColor: this.experienceForm.value.experienceSecondaryColor,
        textColor: this.experienceForm.value.experienceTextColor,
        title: this.experienceForm.value.experienceName,
        description: this.experienceForm.value.experienceNotes,
        url: this.experienceForm.value.experienceUrl,
        isPathWay: this.experienceForm.value.experienceIsPathWay,
        experienceNumber: this.experienceForm.value.experienceNumber,

        markerBGColor: this.experienceForm.value.markerBGColor,
        markerBorderColor: this.experienceForm.value.markerBorderColor,
        markerTextColor: this.experienceForm.value.markerTextColor
      }

      this.isButtonDisabled = true;

      this.mapService.createMapExperience(body).subscribe(
        (response) => {
          this.clearValues();
          this.getMapExperiences();
          this.showSuccessToast("Marcador creado correctamente");
          setTimeout(() => {
            this.isButtonDisabled = false;
            this.actionType = 1;
          }, 1000);
        },
        (error) => {
          console.error(error);
          this.showErrorToast(error)
        }
      )
    } else if (this.actionType == 2) {
      this.isButtonDisabled = true;
      let body = {
        id: this.selectedMark.id,
        lat: this.experienceForm.value.experienceLat,
        lon: this.experienceForm.value.experienceLon,
        color: this.experienceForm.value.experienceColor,
        secondaryColor: this.experienceForm.value.experienceSecondaryColor,
        textColor: this.experienceForm.value.experienceTextColor,
        title: this.experienceForm.value.experienceName,
        description: this.experienceForm.value.experienceNotes,
        url: this.experienceForm.value.experienceUrl,
        isPathWay: this.experienceForm.value.experienceIsPathWay,
        experienceNumber: this.experienceForm.value.experienceNumber,

        markerBGColor: this.experienceForm.value.markerBGColor,
        markerBorderColor: this.experienceForm.value.markerBorderColor,
        markerTextColor: this.experienceForm.value.markerTextColor
      }

      this.mapService.parchMapExperience(body).subscribe(
        (response) => {
          this.clearValues();
          this.getMapExperiences();
          this.showSuccessToast("Marcador editado correctamente");
          setTimeout(() => {
            this.isButtonDisabled = false;
            this.actionType = 1;
          }, 1000);
        },
        (error) => {
          console.error(error);
          this.showErrorToast(error)
        }
      )
    }
  }

  deleteMark(mark: number) {
    const experience = this.mapExperiences.find(exp => exp.getId() === mark);

    if (experience) {
      this.idSelectedMark = experience.getId();
      this.popupConfirmationDelete = true;
    } else {
      console.error('No se encontró la experiencia con id:', mark);
    }
  }

  confirmDelete() {
    if (this.idSelectedMark !== undefined) {
      this.mapService.deleteMapExperience(this.selectedMark.id).subscribe(
        (response: any) => {
          this.popupConfirmationDelete = false;
          this.popupConfirmation = true;
          this.popupText = "Experiencia eliminada correctamente";
          this.actionType = 0;
          this.getMapExperiences();
        },
        (error) => {
          console.error(error);
          this.showErrorToast(error)
        }
      );
    } else {
      console.error('No se ha seleccionado un marcador para eliminar');
    }
  }

  confirmPopup() {
    this.popupConfirmation = false;
  }


  changeSelector(selector: number) {
    this.outdoorSelector = selector;
  }


  toggleSortExperienceNumber(): void {
    if (this.sortOrder === 'asc') {
      this.sortedMapExperience = [...this.mapExperiences].sort((a, b) => a.getExperienceNumber() - b.getExperienceNumber());
      this.sortOrder = 'desc';
    } else {
      this.sortedMapExperience = [...this.mapExperiences].sort((a, b) => b.getExperienceNumber() - a.getExperienceNumber());
      this.sortOrder = 'asc';
    }
  }

  toggleSortByName(): void {
    if (this.sortOrder === 'asc') {
      this.sortedMapExperience = [...this.mapExperiences].sort((a, b) => a.getTitle().localeCompare(b.getTitle()));
      this.sortOrder = 'desc';
    } else {
      this.sortedMapExperience = [...this.mapExperiences].sort((a, b) => b.getTitle().localeCompare(a.getTitle()));
      this.sortOrder = 'asc';
    }
  }

  toggleSortByLocation(): void {
    if (this.sortOrder === 'asc') {
      this.sortedMapExperience = [...this.mapExperiences].sort((a, b) => (a.getIsPathWay() === b.getIsPathWay()) ? 0 : a.getIsPathWay() ? -1 : 1);
      this.sortOrder = 'desc';
    } else {
      this.sortedMapExperience = [...this.mapExperiences].sort((a, b) => (a.getIsPathWay() === b.getIsPathWay()) ? 0 : a.getIsPathWay() ? 1 : -1);
      this.sortOrder = 'asc';
    }
  }

  getMapExperienceById(id: number): number {
    return this.mapExperiences.find(exp => exp.getId() === id)!.getExperienceNumber();
  }

  showSuccessToast(message: string) {
    this.toastService.success("", message, {
      toastClass: 'ngx-toastr toast-success',
      progressBar: true,
      timeOut: 3000
    });
  }

  showErrorToast(error: any) {
    this.toastService.error(error.error, 'Error', {
      toastClass: 'ngx-toastr toast-error',
      progressBar: true,
      timeOut: 3000
    });
  }

  getMarkerHtml(): string {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="43.292" height="39.1" viewBox="0 0 86.584 78.201" style="pointer-events: none">
    <path id="triangle1"
      d="M172.108,2.617h-.116L124.6,2.878a19.9,19.9,0,0,0-4.223.475h0l-.01,0a19.066,19.066,0,0,0-6.906,3.077,18.775,18.775,0,0,0-6.568,8.375,18.128,18.128,0,0,0-1.217,5.227,17.8,17.8,0,0,0,.422,5.527,18.394,18.394,0,0,0,2.147,5.252l23.932,39.812a18.78,18.78,0,0,0,4.072,4.759,19.105,19.105,0,0,0,8,3.94,19.788,19.788,0,0,0,8.707.015,19.2,19.2,0,0,0,6.912-3.06,18.739,18.739,0,0,0,5.343-5.836L188.674,30.37a18.285,18.285,0,0,0,2.34-6.646,17.9,17.9,0,0,0-.3-6.66,18.456,18.456,0,0,0-2.363-5.611,18.892,18.892,0,0,0-6.715-6.371,19.134,19.134,0,0,0-4.553-1.834,19.76,19.76,0,0,0-4.971-.63"
      transform="translate(-105.112 -2.117)" fill="${this.markerBGColor}" stroke="${this.markerBorderColor}" stroke-width="2"/>
    <text id="1" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="20" font-family="Arial" fill="${this.markerTextColor}"> ${this.markerNumber != null ? this.markerNumber : ''}</text>
  </svg>
  `
  }

  getMarkerHtmlWithParameters(bgColor: string, borderColor: string, textColor: string, markerNumber: number) {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="43.292" height="39.1" viewBox="0 0 86.584 78.201" style="pointer-events: none">
    <path id="triangle1"
      d="M172.108,2.617h-.116L124.6,2.878a19.9,19.9,0,0,0-4.223.475h0l-.01,0a19.066,19.066,0,0,0-6.906,3.077,18.775,18.775,0,0,0-6.568,8.375,18.128,18.128,0,0,0-1.217,5.227,17.8,17.8,0,0,0,.422,5.527,18.394,18.394,0,0,0,2.147,5.252l23.932,39.812a18.78,18.78,0,0,0,4.072,4.759,19.105,19.105,0,0,0,8,3.94,19.788,19.788,0,0,0,8.707.015,19.2,19.2,0,0,0,6.912-3.06,18.739,18.739,0,0,0,5.343-5.836L188.674,30.37a18.285,18.285,0,0,0,2.34-6.646,17.9,17.9,0,0,0-.3-6.66,18.456,18.456,0,0,0-2.363-5.611,18.892,18.892,0,0,0-6.715-6.371,19.134,19.134,0,0,0-4.553-1.834,19.76,19.76,0,0,0-4.971-.63"
      transform="translate(-105.112 -2.117)" fill="${bgColor}" stroke="${borderColor}" stroke-width="2"/>
    <text id="1" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="20" font-family="Arial" fill="${textColor}"> ${markerNumber >= 0 ? markerNumber : ''}</text>
  </svg>
  `
  }

  getBuildings(): GeoJSON.FeatureCollection {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-3.741082926070476, 40.41346711515939],
                [-3.7413906451006085, 40.41326257150308],
                [-3.7413340734750022, 40.4132074682274],
                [-3.7411492545506917, 40.4133307543089],
                [-3.740963965450817, 40.41316119668659],
                [-3.7408292944641346, 40.41324746762365],
                [-3.741082926070476, 40.41346711515939],
              ],
            ],
          },
          properties: {
            color: '#28F5B9',
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-3.7421840137214133, 40.4136115155531],
                [-3.742156871876716, 40.41350253034312],
                [-3.7420211712232287, 40.41352195149011],
                [-3.7419915823579686, 40.413419407770476],
                [-3.7418701659836415, 40.41344349002179],
                [-3.741836495895967, 40.41334871466074],
                [-3.7417048764649508, 40.41336269791873],
                [-3.7416691657665524, 40.413246947526204],
                [-3.7419558716595986, 40.41320577451924],
                [-3.741981379301336, 40.41330987226118],
                [-3.742117079955875, 40.413289674204435],
                [-3.742146668820027, 40.41338911073197],
                [-3.742275227334403, 40.413370466394326],
                [-3.742324811879172, 40.41358606193461],
                [-3.7421840137214133, 40.4136115155531],
              ],
            ],
          },
          properties: {
            color: '#0500F5',
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-3.740905988397543, 40.412914827663485],
                [-3.740842037043109, 40.41297156447726],
                [-3.740855383412054, 40.412983419925155],
                [-3.740849266326393, 40.41298807742194],
                [-3.740612368264948, 40.412831839401605],
                [-3.7405211680720925, 40.41290551265942],
                [-3.7408142762907914, 40.41310213901957],
                [-3.7409783254173874, 40.41295860353418],
                [-3.740904920384253, 40.41291499239671],
                [-3.740905988397543, 40.412914827663485],
              ],
            ],
          },
          properties: {
            color: '#FF0074',
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-3.7404143380402957, 40.4129151254339],
                [-3.7403999377233106, 40.412989766894384],
                [-3.7403783372496946, 40.41300157457525],
                [-3.740148063742538, 40.41285189909544],
                [-3.740138094293087, 40.41286117657802],
                [-3.740072185152883, 40.41281521085503],
                [-3.7400998780699126, 40.41278864349593],
                [-3.740136432718373, 40.41279033031256],
                [-3.7401608024847803, 40.41277177532609],
                [-3.740155263901613, 40.412755750561075],
                [-3.740128124843608, 40.412732556814746],
                [-3.740118155399017, 40.41269064547146],
                [-3.7402062188718332, 40.41268769353803],
                [-3.740208434304435, 40.4126995012719],
                [-3.740484255747134, 40.41268052455544],
                [-3.740498102205038, 40.41276022672895],
                [-3.740427208340776, 40.41276655229419],
                [-3.7403779149500735, 40.41288758132836],
                [-3.7404143380402957, 40.4129151254339],
              ],
            ],
          },
          properties: {
            color: '#88CACD',
          },
        },
      ],
    };
  }

  toggleMapAbiablility() {
    this.mapService.updateMapEnabled({enabled: this.isMapEnabled ? 0 : 1}).subscribe((data: any) => this.isMapEnabled = !this.isMapEnabled,
    (error: any) => this.showErrorToast(error))
  }

}
