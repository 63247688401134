import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getMapExperiences(){
    return this.http.get(this.apiUrl+ "/map_experiences")
  }

  getExperienceByMapId(id: number){
    return this.http.get(this.apiUrl+ "/map_experience/"+id)
  }

  createMapExperience(body: any) {
    return this.http.post(this.apiUrl+"/map_experience", body)
  }

  deleteMapExperience(id: number) {
    return this.http.delete(this.apiUrl+"/map_experience/" + id)
  }

  parchMapExperience(body: any) {
    return this.http.patch(this.apiUrl+"/map_experience", body)
  }

  getMapEnabled(){
    return this.http.get(this.apiUrl+ "/map_enabled")
  }

  updateMapEnabled(body: any) {
    return this.http.patch(this.apiUrl+"/map_enabled", body)
  }
}
