import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  NgbDate,
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import {
  AuthService,
  BookingService,
  isMonth,
  Month,
} from 'src/app/shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import {
  ApiConfig,
  AvailableType,
  Booking,
  BookingType,
  DailyAvailability,
  MonthlyAvailability,
  TimeSlot,
  User,
} from 'src/app/shared/models';
import {
  dateToNgbDateStruct,
  formatDateToSpanishString,
} from 'src/app/shared/utils';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TemplatesMonthInfo } from 'src/app/shared/models/TemplatesMonthInfo';
import { TemplatesService } from 'src/app/shared/services/templates.service';
import { Template } from 'src/app/shared/models/Template';

enum QuantityType {
  General = 'general',
  Special = 'special',
}

interface IdTokenClaims {
  extension_legalCountry?: string;
  extension_dateOfBirth?: string;
  postalCode?: string;
  emails?: string[];
  [key: string]: any; // Agrega esto para permitir propiedades adicionales si no estás seguro de todas las claves posibles
}


const DESKTOP_WIDTH = 1024;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() showLegend: boolean = false;
  @Input() showFooter: boolean = false;
  @Input() showTemplate: boolean = false;
  @Input() outsideDays: string = 'visible';
  @Input() showDay: boolean = false;
  @Input() showDay2: boolean = false;
  @Input() showClose: boolean = false;
  @Input() isBorderless: boolean = false;
  @Input() bgColor: string = "rgba(33, 57, 72, 0.7019607843)"
  @Input() paddingl: number = 35;
  @Input() paddingr: number = 35;
  @Input() divSelector: number = 0
  @Input() isConfiguration: boolean = false
  @Input() seeColors: boolean = false
  @Input() extraPadding: boolean = false
  @Output() currentSectionChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() datePicked: EventEmitter<NgbDateStruct> = new EventEmitter<NgbDateStruct>();
  @Output() resetConfig: EventEmitter<void> = new EventEmitter<void>();
  @Output() buttonClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() closeEvent = new EventEmitter<void>();
  @Output() emitDayBookings: EventEmitter<any> = new EventEmitter<any>();
  templateId: number = 0
  loginDisplay = false;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any = [];
  typeEvent: number = 0;

  today = new Date();
  model: NgbDateStruct = {
    day: this.today.getDate(),
    month: this.today.getMonth() + 1,
    year: this.today.getFullYear(),
  };
  date: { year: number; month: number } = {
    year: this.today.getFullYear(),
    month: this.today.getMonth() + 1,
  };

  availableDates: NgbDateStruct[] = [];
  lowAvailabilityDates: NgbDateStruct[] = [];
  templatesInfo: TemplatesMonthInfo | undefined

  morningData: DailyAvailability[] = [];
  afternoonData: DailyAvailability[] = [];

  selectedTime?: DailyAvailability;

  navigation = 'none';

  templates = [
    { id: null, templateName: "Loading...", templateColour: "#000000" }
  ]

  private readonly I18N_VALUES = {
    es: {
      weekdays: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
  };

  QuantityType: typeof QuantityType = QuantityType;
  TimeSlot: typeof TimeSlot = TimeSlot;

  timeSlotSelected?: TimeSlot;

  generalQuantity = 0;
  specialQuantity = 0;

  hasCalendarData = true;

  isAccepted = false;

  user?: User | null;

  hasActiveBooking = false;
  activeBooking?: Booking;
  apiConfig?: ApiConfig;

  private subscriptions: Subscription;

  selectedDate?: NgbDateStruct;
  pinkDates: NgbDateStruct[] = [];
  resultados: any[] = [];

  currentModule: number = 0;
  templatesWork: Template[] = [];
  @ViewChild('pickTheDate', { static: false }) pickTheDate!: ElementRef;
  @ViewChild('todayButtonLeft') todayButtonLeft: any;
  @ViewChild('todayButtonRight') todayButtonRight: any;

  @ViewChildren('pickTheTimes') pickTheTimes!: QueryList<ElementRef>;
  @ViewChildren('timeSlots') timeSlots!: QueryList<ElementRef>;

  constructor(
    private bookingService: BookingService,
    private modalService: NgbModal,
    private toastService: ToastrService,
    private authService: AuthService,
    private templatesService: TemplatesService,
    private router: Router,
  ) {
    this.subscriptions = this.authService.user$.subscribe((userLogged: User | null) => this.user = userLogged);

    this.subscriptions = this.bookingService.apiConfig$.subscribe(
      (apiConfig: ApiConfig | null) => {
        if (!apiConfig) return;

        this.apiConfig = apiConfig;
      },
    );
  }

  ngAfterViewInit(): void {
  }

  async ngOnInit(): Promise<void> {
    this.getTemplates();
  }

  getTemplates() {
    this.templatesService.getTemplates().subscribe((templates: any) => {
      this.templatesWork = templates.map((template: any) =>
        new Template(
          template.id,
          template.template_name,
          template.template_status,
          template.morning_type,
          template.morning_start_hour,
          template.morning_end_time,
          template.morning_visit_time,
          template.morning_max_people,
          template.morning_max_group_people,
          template.afternoon_type,
          template.afternoon_start_hour,
          template.afternoon_end_time,
          template.afternoon_visit_time,
          template.afternoon_max_people,
          template.afternoon_max_group_people,
          template.template_colour,
          template.nota
        ))
      if (this.showDay) this.todayButtonRight.nativeElement.click()
      if (this.showDay2) this.todayButtonLeft.nativeElement.click()
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.typeEvent = this.divSelector
  }

  determineBookingType({
    generalQuantity,
    specialQuantity,
  }: {
    generalQuantity: number;
    specialQuantity: number;
  }): BookingType {
    if (generalQuantity > 0 && specialQuantity === 0) return BookingType.particular;
    if (specialQuantity > 0 && generalQuantity === 0) return BookingType.collective;
    return BookingType.particular;
  }

  navigate(datepicker: NgbDatepicker, number: number): void {
    this.resetDataSelection();
    const { state, calendar } = datepicker;
    datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));

    const date: NgbDate = calendar.getNext(state.firstDate, 'm', number);

    this.model = {
      ...this.model,
      month: date.month,
    };
    this.getCalendarData();
  }

  isDisabled(date: NgbDateStruct) {
    if (!this.hasCalendarData) return true;

    return !this.isAvailable(date) && !this.isLowAvailability(date);
  }

  async getAvailableTimes(date: NgbDateStruct): Promise<void> {
    this.resetDataSelection();
    const { day, month: monthNumber, year } = date;
    this.datePicked.emit(date);
    this.typeEvent = 0;

    if (this.isDisabled(date)) {
      this.morningData = [];
      this.afternoonData = [];
      return;
    }
    const bookingType = this.determineBookingType({
      generalQuantity: this.generalQuantity,
      specialQuantity: this.specialQuantity,
    });

    try {
      if (!isMonth(monthNumber)) return;
      const visitors = this.visitorsSelected();
      const month: Month = monthNumber;
      const response = await this.bookingService.getAvailableBookingsByDay({
        reserve_type: bookingType,
        visite_size: visitors,
        day,
        month,
        year,
      });
      const groupedDailyData = this.groupByTimeSlot(response);
      this.morningData = groupedDailyData.morning ?? [];
      this.afternoonData = groupedDailyData.afternoon ?? [];
      const isMobile = window.innerWidth < DESKTOP_WIDTH;
      if (isMobile) this.scrollToElement(this.pickTheTimes);
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) errorMessage = error.error.error;
      else errorMessage = 'Error desconocido';
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
      this.morningData = [];
      this.afternoonData = [];
    }
  }

  getIdTemplateByDay(formattedDate: string) {
    let templateId: number = 0;
    if (this.templatesInfo) {
      let template = this.templatesInfo.templates.find((template) => { return template.dates.includes(formattedDate) });
      if (template) templateId = template.id;
    }
    return templateId;
  }

  groupByTimeSlot(availabilityArray: DailyAvailability[]): Record<TimeSlot, DailyAvailability[]> {
    return availabilityArray.reduce((acc, availability) => {
      const { timeSlot } = availability;
      if (!acc[timeSlot]) acc[timeSlot] = [];
      acc[timeSlot].push(availability);
      return acc;
    }, {} as Record<TimeSlot, DailyAvailability[]>);
  }

  trackByMonth(index: number, month: any): number {
    return month.month;
  }

  isAvailable(date: NgbDateStruct): boolean {
    return this.availableDates.some(
      (d) =>
        d.year === date.year && d.month === date.month && d.day === date.day,
    );
  }

  isLowAvailability(date: NgbDateStruct): boolean {
    return this.lowAvailabilityDates.some(
      (d) =>
        d.year === date.year && d.month === date.month && d.day === date.day,
    );
  }

  scrollToElement(elements: QueryList<ElementRef>): void {
    const element = elements.first;
    if (element) {
      setTimeout(() => {
        element.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 10);
    }
  }

  showTimeSlots(timeSlot: TimeSlot): void {
    this.timeSlotSelected = timeSlot;
    const isMobile = window.innerWidth < DESKTOP_WIDTH;
    if (isMobile) this.scrollToElement(this.timeSlots);
  }

  selectSlotData(dailySlot: DailyAvailability): void {
    this.selectedTime = dailySlot;
  }

  openPrivacyPolicy(content: TemplateRef<HTMLElement>): void {
    this.openModal(content);
  }

  async openModal(
    content: TemplateRef<HTMLElement>,
    size: 'sm' | 'lg' | 'xl' | string = 'lg',
  ): Promise<void> {
    const modalRef: NgbModalRef = this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
      size,
    });
    try {
      await modalRef.result;
    } catch (error) {
      // TODO: handle error
    } finally {
      return;
    }
  }

  bookSlot(content: TemplateRef<HTMLElement>): void {
    this.modalService
      .open(content, { size: 'sm', centered: true, backdrop: 'static' })
      .result.then(() => {
        this.confirmBooking();
      });
  }

  async confirmBooking(): Promise<void> {
    if (!this.selectedTime || !this.user) return;

    const visitors = this.visitorsSelected();
    try {
      await this.bookingService.createBooking({
        user_id: this.user.id,
        visita_id: this.selectedTime.visitId,
        num_visitor: visitors,
      });

      this.toastService.success(
        this.displayBookingDate(),
        this.getQuantityText() ?? '',
      );

      this.navigateToMyBookings();
      document.body.scrollTop = 0;
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'Error desconocido';
      }
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
    }
  }

  navigateToMyBookings(): void {
    this.router.navigate(['/mis-reservas']);
  }

  getQuantityText(): string | void {
    if (this.generalQuantity > 0) {
      return `${this.generalQuantity}x Pases Generales`;
    }
    if (this.specialQuantity > 0) {
      return `${this.specialQuantity}x Pases Especiales`;
    }
  }

  displayBookingDate(): string {
    if (!this.selectedTime) {
      return '';
    }

    return formatDateToSpanishString(this.selectedTime.date);
  }

  async getCalendarData(): Promise<void> {
    const visitors = this.visitorsSelected();
    try {
      if (!isMonth(this.model.month)) return;
      await this.requestCalendar(BookingType.particular, visitors);
    } catch (error: any) {
      let errorMessage = '';
      await this.requestCalendar(BookingType.collective, visitors);
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'No se han podido obtener datos';
      }
      // this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
      this.availableDates = [];
      this.lowAvailabilityDates = [];
      this.hasCalendarData = true;
    }
  }

  async requestCalendar(bookingType: BookingType, visitors: number) {
    const month: any = this.model.month;
    const response2 = await this.bookingService.getUsedTemplatesByMonth(month, this.model.year);
    this.templatesInfo = response2;
    let aux: { id: null , templateName: string, templateColour: string } []= []
    response2.templates.forEach((template: any) => {
      aux.push({ id: template.id, templateName: template.template_name, templateColour: template.template_colour})
    })
    this.templates = aux
    this.hasCalendarData = true;
    const isMobile = window.innerWidth < DESKTOP_WIDTH;
    if (isMobile) this.scrollToCalendar();
  }

  getAvailabilityData(monthlyAvailabilities: MonthlyAvailability[]): {
    available: NgbDateStruct[];
    lowAvailable: NgbDateStruct[];
  } {
    const available: NgbDateStruct[] = [];
    const lowAvailable: NgbDateStruct[] = [];

    monthlyAvailabilities.forEach((availabilityEntry) => {
      const ngbDate = dateToNgbDateStruct(availabilityEntry.date!);
      if (availabilityEntry.availability === AvailableType.Available) available.push(ngbDate);
      else if (availabilityEntry.availability === AvailableType.Half_Full) lowAvailable.push(ngbDate);

    });
    return { available, lowAvailable };
  }

  scrollToCalendar() {
    if (this.pickTheDate) {
      setTimeout(() => {
        this.pickTheDate.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 0);
    }
  }

  resetDataSelection(): void {
    // this.availableDates = [];
    // this.lowAvailabilityDates = [];
    this.morningData = [];
    this.afternoonData = [];
    this.selectedTime = undefined;
    this.timeSlotSelected = undefined;
    // this.hasCalendarData = false;
    this.isAccepted = false;
  }

  visitorsSelected(): number {
    let visitors = 0;
    if (this.generalQuantity > 0) {
      visitors = this.generalQuantity;
    }
    if (this.specialQuantity > 0) {
      visitors = this.specialQuantity;
    }
    return visitors;
  }

  buttonClickEvent(typeEvent: number) {
    // 1: Editar Calendario 2: Editar dia
    this.typeEvent = typeEvent
    this.buttonClicked.emit(this.typeEvent)
  }

  goToToday(datepicker: NgbDatepicker) {
    const today = new Date();
    this.model = {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    };
    datepicker.navigateTo(this.model);
    this.getCalendarData();
    this.getAvailableTimes(this.model);
  }

  getStyleBasedOnDate(date: NgbDateStruct) {
    let dateString = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`;
    let color = "color: #9e9e9e;"; // Color por defecto
    if (this.templatesInfo) {
      let template = this.templatesInfo.templates.find((template) => { return template.dates.includes(dateString) });
      if (template) color = "color: " + template.template_colour + ";";
    }
    return color;
  }

  closeCalendar = () => this.buttonClickEvent(0)

  setPink(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dateElement = target.innerText.trim();
    //el texto del elemento se convierte en una fecha NgbDateStruct
    const day = parseInt(dateElement, 10);
    const month = this.date.month;
    const year = this.date.year;
    const selectedDate: NgbDateStruct = { day, month, year };
    const date = new Date(year, month - 1, day);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    this.emitDayBookings.emit(formattedDate);
    if (!this.isConfiguration) return
    //mira si la fecha esta en pinkDates
    const index = this.pinkDates.findIndex(date =>
      date.day === selectedDate.day &&
      date.month === selectedDate.month &&
      date.year === selectedDate.year
    );
    if (index === -1) {
      //cambia a rosa y agrega la array si no esta
      this.pinkDates.push(selectedDate);
      target.style.background = "#FF0074";
    } else {
      //quita el color rosa si esta  y elimina de la array
      this.pinkDates.splice(index, 1); //elimina array
      target.style.background = ""; //restablece fondo
    }
  }

  sendDaysWithTemplate() {
    /* if (this.pinkDates.length == 0 || this.templateId == 0) return */
    let days = this.pinkDates.map((element: any) => { return `${element.year}-${element.month.toString().padStart(2, '0')}-${element.day.toString().padStart(2, '0')}`; })
    this.templatesService.applyTemplateToDays({ template_id: this.templateId, dates: days }).subscribe(
      (data: any) => { this.resetConfig.emit(); this.showSuccessToast("Plantilla añadida correctamente") }, (err: any) => { this.showErrorToast(err) })
  }

  showErrorToast(error: any) {
    this.toastService.error(error.error, 'Error', {
      toastClass: 'ngx-toastr toast-error',
      progressBar: true,
      timeOut: 3000
    });
  }

  showSuccessToast(message: string) {
    this.toastService.success("", message, {
      toastClass: 'ngx-toastr toast-success',
      progressBar: true,
      timeOut: 3000
    });
  }


}
